import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PolaHeaderIcon from '../../icons/PolaHeaderIcon';
import RdsBackIcon from '../../icons/RdsBackIcon';
import RDSButton from '../../components/common/RDSButton';
import * as imgActionCreator from '../../actions/imgActionCreator';
import * as orderActionCreator from '../../actions/orderActionCreator';
import * as fbEventActionCreator from '../../actions/fbEventActionCreator';
import * as globalAlertActionCreator from '../../actions/globalAlertActionCreator';
import * as TranslationSelectors from '../../selectors/TranslationSelectors';
import Loading from '../../components/common/LoadingIndicator';

import classNames from 'classnames';

import AntiWrinkleSerumImg from '../../images/pola-product/anti-wrinkle-serum.jpg';
import WrinkleSerumImg from '../../images/pola-product/wrinkle-serum.jpg';

import PagePathConstants from '../../constants/router/PagePathConstants';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import CookieUtil from '../../utils/CookieUtil';
import tracker from '../../tracker';
import * as FBUtil from '../../utils/FbUtil';
import { generateRandomString } from '../../utils/RandomGeneratorUtils';
import { sendCustomEvent } from '../../apis/ConfigAPI';


const PRODUCT_LIST = [{
    key: 'リンクルショット メディカル セラム N <20g>',
    title: 'ポーラ リンクルショット メディカル セラム N  <20g>',
    disabled: false,
    img: AntiWrinkleSerumImg,
    trialTime: Math.floor(Math.random() * (300 - 200 + 1)) + 200,
}, {
    key: 'ポーラ WRS セラム N <40g>',
    title: 'ポーラ WRS セラム N <40g>',
    disabled: true,
    img: WrinkleSerumImg,
    trialTime: 0,
}];

class VehicleModelSelectorPage extends Component {
    constructor(props, context) {
        super(props, context);
        const cacheTrialProduct = CookieUtil.loadTrialProduct();
        let selectedVehicleModel = Object.assign({}, PRODUCT_LIST[0]);
        if (cacheTrialProduct && cacheTrialProduct.hasOwnProperty(PRODUCT_LIST[0].key)) {
            selectedVehicleModel.trialTime = cacheTrialProduct[PRODUCT_LIST[0].key];
        }
        this.state = {
            selectedVehicleModel: selectedVehicleModel
        };

        this.handleSelectVehicleModel = this.handleSelectVehicleModel.bind(this);
        this.goBack = this.goBack.bind(this);
        this.prefillVehicleModel = this.prefillVehicleModel.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.saveVehicleModalTrialTime = this.saveVehicleModalTrialTime.bind(this);
    }

    componentDidMount() {
        this.saveVehicleModalTrialTime();
        this.prefillVehicleModel();
    }

    saveVehicleModalTrialTime() {
        const cacheTrialProduct = CookieUtil.loadTrialProduct();
        if (!cacheTrialProduct) {
            const cacheData = {
                [this.state.selectedVehicleModel.key]: this.state.selectedVehicleModel.trialTime
            }
            CookieUtil.saveTrialProduct(cacheData);
        }
    }

    prefillVehicleModel() {
        const { location } = this.props;
        const orderInfo = LocalStorageUtil.loadOrderDetail();
        const queryParams = queryString.parse(location.search);
        let selectedVehicleModel;
        if (orderInfo.vehicleModel) {
            selectedVehicleModel = PRODUCT_LIST.filter(item => item.key === orderInfo.vehicleModel)[0];
        }
        if (queryParams.vehicleModel) {
            selectedVehicleModel = PRODUCT_LIST.filter(item => item.key === queryParams.vehicleModel)[0];
        }
        this.setState({ selectedVehicleModel: selectedVehicleModel ? selectedVehicleModel : this.state.selectedVehicleModel });
    }

    handleSelectVehicleModel(model) {
        const { fbEventActions } = this.props;
        const {selectedVehicleModel} = this.state;
        if (model.disabled || selectedVehicleModel.key === model.key) return;

        const eventId = generateRandomString(10);
        const eventParams = { selectedSku: model };
        sendCustomEvent('CustomizeProduct');
        FBUtil.trackFBEvent('CustomizeProduct', eventParams, eventId);
        fbEventActions && fbEventActions.submitFBConversionEvent('CustomizeProduct', eventParams, null, eventId, null);

        let cacheTrialProduct = CookieUtil.loadTrialProduct();
        if (cacheTrialProduct && cacheTrialProduct.hasOwnProperty(model.key)) {
            // 需要从缓存中取使用次数，并且递减一点
            let latestTrialNum;
            let end = cacheTrialProduct[model.key];
            if (end < 10) {
                latestTrialNum = Math.floor(Math.random() * (end - 1 + 1)) + 1;
                model.trialTime = latestTrialNum;
                this.setState({ selectedVehicleModel: model });
                cacheTrialProduct[model.key] = latestTrialNum;
                CookieUtil.saveTrialProduct(cacheTrialProduct);
                return;
            }
            let start = cacheTrialProduct[model.key] - 10;
            latestTrialNum = Math.floor(Math.random() * (end - start + 1)) + start;
            model.trialTime = latestTrialNum;
            cacheTrialProduct[model.key] = latestTrialNum;
        } else {
            if (cacheTrialProduct) {
                cacheTrialProduct[model.key] = model.trialTime;
            } else {
                cacheTrialProduct = {};
                cacheTrialProduct[model.key] = model.trialTime;
            }
        }
        CookieUtil.saveTrialProduct(cacheTrialProduct);
        this.setState({ selectedVehicleModel: model });
    }

    goBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    handleNextPage() {
        const { selectedVehicleModel } = this.state;
        const { history, location, fbEventActions } = this.props;
        let orderInfo = { vehicleModel: selectedVehicleModel.key };
        tracker.trackVehicleModel(selectedVehicleModel.key);
        LocalStorageUtil.saveOrderDetail(orderInfo);

        const eventParams = {
            content_ids: 'skin-trial',
            content_name: selectedVehicleModel.key,
            content_type: 'product',
            contents: [{
                id: 'skin-trial',
                title: selectedVehicleModel.key,
                quantity: 1,
            }],
            currency: 'JPY',
            value: localStorage.getItem('payment_amount')
        };

        const eventId = generateRandomString(10);
        sendCustomEvent('AddToCart');
        FBUtil.trackFBEvent('AddToCart', eventParams, eventId);
        fbEventActions && fbEventActions.submitFBConversionEvent('AddToCart', eventParams, null, eventId, null);


        setTimeout(() => {
            history && history.push({ pathname: PagePathConstants.CUSTOM_INFO, search: location.search });
        }, 500);
    }

    render() {
        const { showLoading, selectedVehicleModel } = this.state;
        let translations = this.props.translations && this.props.translations['submission_page'];
        let trialTimeText = selectedVehicleModel.trialTime > 0 ? `今日も${selectedVehicleModel.trialTime}のトライアルチャンス` : '本日の試用枠は全て終了しました。明日またお越しください！';
        return (
            <div className='tw-w-full tw-h-full gotham-font tw-text-[#393c41] '>
                {showLoading && <Loading />}
                <div className='tw-w-full tw-h-full phone:tw-h-full tw-pb-[200px] phone:tw-pb-[50px] tw-overflow-scroll'>
                    <div className='tw-z-[100] tw-w-full tw-h-[70px] tw-flex tw-justify-between'>
                        <div className='tw-w-1/3 tw-h-full tw-flex tw-items-center tw-pl-[30px]'><RdsBackIcon color='#171a20' onClick={this.goBack} /></div>
                        <PolaHeaderIcon size={90} />
                        <div className='tw-w-1/3'></div>
                    </div>
                    <div className='tw-mt-[66px] tw-w-[660px] tw-mx-auto phone:tw-px-[30px] phone:tw-w-full phone:tw-mt-[16px]'>
                        <div className='tw-text-[40px] tw-leading-[40px] tw-font-[500] phone:tw-text-[20px]'>{translations && translations.title} <span className='tw-ml-[10px] tw-text-[14px]'>{translations && translations.test_drive_description}</span> </div>
                        <p className='tw-mt-0 tw-text-[14px] tw-font-[500] tw-mt-[5px] tw-h-[60px]'>{selectedVehicleModel.title} <span className='tw-ml-[10px]'>({trialTimeText})</span></p>
                        <div className='tw-mt-[20px] tw-w-full tw-h-[275px] phone:tw-h-auto tw-flex phone:tw-flex-col tw-justify-between tw-items-center'>
                            <div className='tw-w-[204px] phone:!tw-w-full  phone:tw-h-[180px] '>
                                {PRODUCT_LIST.map((item) => {
                                    return (<div key={item.key} onClick={() => this.handleSelectVehicleModel(item)}
                                        className={classNames('tw-mx-auto tw-w-[204px] phone:tw-w-[85%] tw-min-h-[50px] tw-border tw-rounded-[10px] tw-font-[500] tw-flex tw-justify-center tw-items-center tw-py-[10px] tw-px-[15px] tw-mb-[20px] tw-text-[14px] tw-text-center',
                                            { 'tw-border-[2px] tw-border-brand-primary': selectedVehicleModel.key === item.key },
                                            { 'tw-border-[#a2a3a5] ': selectedVehicleModel.key !== item.key },
                                            { 'tw-text-body-text-2 ': item.disabled })}>{item.title}</div>)
                                })}
                            </div>
                            <div className='tw-w-[432px] phone:tw-w-full tw-h-full phone:tw-h-[245px] phone:tw-mt-0'>
                                <div className='tw-w-full tw-h-[240px]' style={{ backgroundImage: `url(${selectedVehicleModel.img})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                            </div>
                        </div>

                        <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-mt-[30px] tw-text-white tw-mt-[80px] phone:tw-mt-[10px] tw-px-[100px] phone:tw-px-[0px]'>
                            <RDSButton
                                color="primary"
                                disabled={selectedVehicleModel.disabled}
                                rectangle
                                className="tw-w-[320px] phone:!tw-w-full"
                                onClick={this.handleNextPage}>
                                <span>次のステップ</span>
                            </RDSButton>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        translations: TranslationSelectors.selectTranslations(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        imgActions: bindActionCreators(imgActionCreator, dispatch),
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
        fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleModelSelectorPage);
