import React from "react";
import classnames from "classnames";

const RDSRadioButton = ({
  checked, onClick, id, className
}) => {

  const classes = classnames("tw-min-w-[20px] tw-rounded-full tw-inline-flex tw-items-center tw-justify-center tw-w-[20px] tw-h-[20px] tw-cursor-pointer",
    {
      'tw-bg-brand-primary': checked,
      'tw-border-[1.5px] tw-border-disable-text': !checked
    })

  const inputClasses = classnames(
    "tw-w-[20px] tw-h-[20px] tw-appearance-none checked:tw-ring-2 checked:tw-ring-offset-2 checked:tw-rounded-full checked:tw-w-[12px] checked:tw-h-[12px] tw-cursor-pointer",
    className)

  return (
    <div className={classes}  >
      <input type="radio"
        className={inputClasses}
        onChange={() => { onClick && onClick(id) }}
        checked={checked}
      />
    </div>
  );
};

export default RDSRadioButton;
