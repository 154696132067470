
import React from 'react';
import Colors from '../constants/ColorConstants';

const PolaHeaderIcon = ({
    size = 24,
    color = '#000',
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg viewBox="0 0 99 25" width={size} {...props}>
        <g>
            <path d="M62.88,0.77h-6.72v23.45h14.18v-5.39h-7.45V0.77" fill={color}/>
            <path d="M6.93,11.79V6.14h2.63c2.06,0,2.91,1.44,2.91,2.75c0,1.3-0.84,2.9-2.91,2.9H6.93 M11.01,0.77H0v23.45h6.94v-7.08h4.08   c5.89,0,8.33-4.25,8.33-8.26C19.34,4.87,16.82,0.77,11.01,0.77" fill={color}/>
            <path d="M37.13,18.72c-3.43,0-6.23-2.79-6.23-6.21c0-3.45,2.79-6.25,6.23-6.25c3.44,0,6.23,2.8,6.23,6.25   C43.36,15.93,40.57,18.72,37.13,18.72 M37.13,0C29.88,0,24,5.6,24,12.51C24,19.41,29.88,25,37.13,25c7.24,0,13.12-5.59,13.12-12.49   C50.26,5.6,44.37,0,37.13,0" fill={color}/>
            <path d="M84.43,15.4l2.24-6.54h0.11l2.24,6.54H84.43 M89.79,0.77h-6.14l-9.22,23.45h6.97l1.27-3.72h8.09l1.28,3.72H99L89.79,0.77" fill={color}/>
        </g>
    </svg>
);

export default PolaHeaderIcon;