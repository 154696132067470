import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PagePathConstants from '../constants/router/PagePathConstants';
import Loading from '../components/common/LoadingIndicator';
import TeslaIcon from '../icons/TeslaIcon';
import EarthIcon from '../icons/EarthIcon';
import Facebook from '../images/facebook.svg';
import Twitter from '../images/twitter.svg';
import Email from '../images/email.svg';
import * as storyActionCreator from '../actions/storyActionCreator';
import * as storyCommentActionCreator from '../actions/storyCommentActionCreator';
import * as fbEventActionCreator from '../actions/fbEventActionCreator';
import * as DateTimeUtil from '../utils/DateTimeUtil';
import * as TranslationSelectors from '../selectors/TranslationSelectors';
import AntiWrinkleSerumImg from '../images/pola-product/anti-wrinkle-serum.jpg';

import XIcon from '../icons/XIcon';
import AdHeaderImg from '../images/ad-logo.png';
import {sendCustomEvent} from '../apis/ConfigAPI';
import * as FBUtil from '../utils/FbUtil';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/scss/pagination';
import tracker from '../tracker';
import classNames from 'classnames';
import { generateRandomString } from '../utils/RandomGeneratorUtils';
import { STORY_BASE_URL } from '../constants/api/ApiEndpointConstants';

class AdvertisingPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            story: null,
            storyId: 7,
            commentList: [],
            commentTotal: 0,
            showLoading: false,
            showSlidingComponent: false
        }

        this.routeToTeslaRootPage = this.routeToTeslaRootPage.bind(this);
        this.getStoryInfoById = this.getStoryInfoById.bind(this);
        this.getStoryCommentListById = this.getStoryCommentListById.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.closeSliderComponent = this.closeSliderComponent.bind(this);
        this.sendViewContenteEvent = this.sendViewContenteEvent.bind(this);
    }
    scrollContainerRef = React.createRef();

    componentDidMount() {
        this.getStoryInfoById();
        this.getStoryCommentListById();
        sendCustomEvent('RouteToTheStoryPage');
    }

    getStoryInfoById() {
        const { location, storyActions } = this.props;
        const { storyId } = this.state;
        this.toggleLoading();
        storyActions.getStoryInfoById(storyId)
            .then(res => {
                this.toggleLoading();
                const storyInfo = res;
                this.setState({
                    story: storyInfo
                });
            })
            .catch((err) => {
                this.toggleLoading();
            });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    sendViewContenteEvent() {
        const {fbEventActions} = this.props;
        const eventId = generateRandomString(10);
        const eventParams = {
            content_ids: 'skin-trial',
            content_category: 'Skin care product trial',
            content_name: 'Skin care product trial',
            content_type: 'product',
            contents: [{
                id: 'skin-trial',
                title: 'エマルションＵＶコールｎ 100mL',
                quantity: 1,
            }],
            currency: 'JPY',
            value: localStorage.getItem('payment_amount')
        };
        FBUtil.trackFBEvent('ViewContent', eventParams, eventId);
        sendCustomEvent('ViewContent');
        fbEventActions && fbEventActions.submitFBConversionEvent('ViewContent', eventParams, null, eventId, null);
    }

    getStoryCommentListById() {
        let params = { 'tieziid': this.state.storyId };
        const { commentActions } = this.props;
        return commentActions && commentActions.fetchCommentList(params)
            .then(data => {
                if (data.commentList) {
                    const { commentList, total } = data;
                    this.setState({ commentList, commentTotal: total });
                }
            })
            .catch();
    }

    routeToTeslaRootPage() {
        this.sendViewContenteEvent();
        const { history, location } = this.props;
        tracker.trackNewsPage();

        history && history.push({ pathname: PagePathConstants.TESLA, search: location.search });
    }

    randomDate(index) {
        // 随机生成0-11的数字
        const randomMonthNum = Math.floor(Math.random() * 11);
        // 随机生成1-30数字
        const randomDateNum = Math.ceil(Math.random() * 30);
        // 随机生成1-24 数字
        const randomHourNum = Math.ceil(Math.random() * 24);
        // 随机生成1-60 数字
        const randomMinuteNum = Math.ceil(Math.random() * 60);
        let time;
        if (index < 3) {
            time = moment().subtract(index, 'days').hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum)
        } else if (index >= 3 && index < 6) {
            time = moment().subtract(index, 'days').hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum)
        } else {
            time = moment().subtract(index < 9 ? index : 8, 'days').hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum)
        }
        return DateTimeUtil.getFriendlyTime(time);
    }

    handleScroll() {
        const scrollTop = this.scrollContainerRef && this.scrollContainerRef.current.scrollTop;
        if (scrollTop >= 500 && !this.state.showSlidingComponent) {
            this.setState({ showSlidingComponent: true });
        }

        if (scrollTop < 500 && this.state.showSlidingComponent) {
            this.setState({ showSlidingComponent: false });
        }
    }

    closeSliderComponent(e) {
        e && e.stopPropagation();
        this.setState({ showSlidingComponent: false });
    }

    render() {
        const { story, commentList, showLoading, showSlidingComponent } = this.state;
        const mockReportTime = DateTimeUtil.getFriendlyTime(moment(new Date()).subtract(10, 'days').subtract(2, 'hours').subtract(30, 'minutes'));
        const teslaImgArr = [AntiWrinkleSerumImg];
        let { translations } = this.props;
        translations = translations && translations['submission_page'];
        return (
            <div className='tw-w-full tw-h-full' >
                {showLoading && <Loading />}
                {/* {showSlidingComponent && <div className='hs-slider-component tw-fixed tw-left-[20px] tw-bottom-[30px] tw-w-[320px] tw-h-[110px] tw-z-[30] tw-rounded-[10px] tw-flex tw-items-center tw-px-[10px] tw-shadow-xl tw-bg-[#ebf5ff]' onClick={this.routeToTeslaRootPage}>
                    <XIcon className='tw-absolute tw-right-[5px] tw-top-[5px]' onClick={this.closeSliderComponent} />
                    <div className='tw-w-[80px] tw-h-[80px] tw-rounded-[10px] tw-overflow-hidden'>
                        <Swiper
                            className=''
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Navigation, Autoplay]}>
                            {teslaImgArr && teslaImgArr.map((item, i) => {
                                return (<SwiperSlide key={i}>
                                    <div className='tw-w-[80px] tw-h-[80px] tw-bg-white' style={{ backgroundImage: `url(${item})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                </SwiperSlide>)
                            })}
                        </Swiper>
                    </div>
                    <div className='tw-ml-[10px] tw-text-[14px] tw-max-w-[calc(100%-110px)]'>
                        <div className='tw-font-[500]'>新製品を自由に使える 新製品キットが登場</div>
                        <div className='tw-mt-[5px]'>{'本日の残りは%s個。'.replace('%s', Math.floor(Math.random() * (200 - 20 + 1)) + 20)}</div>
                        <div className='tw-mt-[5px] tw-font-bold tw-text-center tw-text-brand-primary'>Apply Now</div>
                    </div>

                </div>} */}
                {showSlidingComponent && <div className='tw-z-[1000001] tw-fixed tw-w-[80%] tw-left-[10%] tw-bottom-[10px] tw-bg-[#d20f27] tw-text-white tw-font-bold tw-cursor-pointer tw-h-[40px] tw-flex tw-items-center tw-justify-center tw-rounded-[6px] hs-ad-apply-btn' onClick={this.routeToTeslaRootPage}>今すぐ応募、5,000名限定!</div>}
                <div className={classNames('tw-w-full tw-h-full ad-bg-container tw-relative tw-flex tw-justify-center tw-overflow-scroll', {'!tw-h-[calc(100%-60px)]' : showSlidingComponent})} onScroll={this.handleScroll} ref={this.scrollContainerRef}>
                    <div className='tw-absolute tw-top-0 tw-left-[50%] tw-ml-[-357px] phone:tw-left-0 phone:tw-ml-[0px] tw-w-[700px] tw-h-[70px] phone:tw-w-full tw-z-[100000] tw-flex tw-items-center tw-px-[16px] tw-bg-gradient-to-b tw-from-black-500'>
                        {/* <img src={AdHeaderImg} className='tw-h-[50px] ' alt='header' /> */}
                        <TeslaIcon size={120} color='white'/>
                        <div className='tw-absolute tw-h-[35px] tw-right-[20px] tw-top-[50%] tw-mt-[-17px] tw-text-[14px] phone:tw-text-[14px] tw-rounded-[5px] tw-px-[15px] tw-py-[7px] tw-bg-[#d20f27] tw-text-white tw-font-bold tw-cursor-pointer hs-ad-apply-btn' onClick={this.routeToTeslaRootPage}>今すぐ応募、5,000名限定!</div>
                    </div>
                    <div className={classNames('tw-w-[700px] phone:tw-w-full tw-text-advertising-text tw-bg-white tw-relative')}>
                        <div className='tw-w-full tw-absolute tw-top-[100px] tw-text-[28px] tw-text-center tw-text-white'>キャンペーン / 試乗イベント</div>
                        <div className='tw-pb-0'>
                            {/* <div className='tw-flex tw-items-center tw-px-[20px] tw-pt-[20px]'>
                                <div className='tw-shrink-0 tw-w-[40px] tw-h-[40px] tw-rounded-full tw-overflow-hidden' style={{ backgroundImage: `url(${story && story.logo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                                <div className='tw-w-[500px] phone:tw-w-[200px] tw-ml-[20px] tw-h-[40px] tw-flex tw-flex-col tw-justify-between tw-items-start'>
                                    <div className='tw-w-full tw-text-[15px] tw-font-bold tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis'>{story && story.nickname}</div>
                                    <div className='tw-text-[13px] tw-text-body-text-grey tw-flex tw-items-center'><EarthIcon width="18" height="18" color='#737B8B' className="tw-mr-[8px]" />{mockReportTime}</div>
                                </div>
                            </div> */}
                            {story && story.logo && story.logo !== '' && <img src={story && story.logo} alt='logo-avatar' className='tw-w-full'/>}
                            <div className='hs-story-description tw-px-[24px] tw-pt-[48px] tw-text-[#393c41] tw-text-[14px]' dangerouslySetInnerHTML={{ __html: story && story.description }}></div>
                            {/* <div className="tw-mx-[20px] tw-flex tw-justify-between tw-items-center tw-border-b  tw-border-advertising-line tw-py-[15px] ">
                                <div className="tw-flex tw-items-center tw-w-[250px]">
                                    <div className='tw-shrink-0 tw-w-[32px] tw-h-[32px] tw-rounded-full tw-overflow-hidden tw-mr-[10px]' style={{ backgroundImage: `url(${story && story.logo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                                    <p className='tw-text-[14px] tw-w-[calc(100% - 42px)] tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis'>By <strong> {story && story.nickname} </strong> </p>
                                </div>
                                <div className="tw-flex tw-justify-between tw-items-center tw-w-[120px]">
                                    <img className='tw-w-[32px] tw-h-[32px]' alt="ad-img-social" src={Facebook} />
                                    <img className='tw-w-[32px] tw-h-[32px]' alt="ad-img-social" src={Twitter} />
                                    <img className='tw-w-[32px] tw-h-[32px]' alt="ad-img-social" src={Email} />
                                </div>
                            </div> */}
                            {/* <div className='tw-mx-[20px] tw-py-[15px] tw-flex tw-justify-between tw-items-center tw-border-advertising-line tw-border-opacity-[0.2] tw-border-b'>
                                <div className='tw-text-[13px] tw-flex tw-items-center'>
                                    <svg width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" fill="#ea442b"></path>
                                    </svg>
                                    <div className='tw-text-body-text-grey tw-ml-[6px]'>15K</div>
                                </div>
                                <div className='tw-flex tw-items-center tw-text-body-text-grey tw-text-[13px]'>
                                    <span>425 comments</span>
                                    <span className='tw-ml-[30px]'>820 shares</span>
                                </div>
                            </div> */}
                            <div className='tw-mt-[15px] tw-mx-[20px] hs-comment-container'>
                                <div className='tw-mb-[20px] tw-w-full tw-border-b tw-border-b-[1px] tw-border-[rgba(15, 17, 17, 0.8)]'></div>
                                {commentList && commentList.map((comment, index) => {
                                    const commentTime = this.randomDate(index);
                                    return (<div className='tw-mb-[18px] tw-flex tw-items-start'>
                                        <div className='tw-mt-[4px] tw-w-[32px] tw-h-[32px] tw-rounded-full tw-overflow-hidden tw-flex-shrink-0' style={{ backgroundImage: `url(${comment && comment.logo && (STORY_BASE_URL + comment.logo)})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                                        <div className='tw-ml-[5px]'>
                                            <div className='tw-px-[12px] tw-py-[8px] tw-rounded-[20px] tw-bg-[#F0F2F5]'>
                                                <div className='tw-font-bold tw-text-[12px]'>{comment && comment.nickname}</div>
                                                <div className='tw-text-[12px] tw-mt-[4px]' dangerouslySetInnerHTML={{ __html: comment && comment.description }}></div>
                                            </div>
                                            <div className='tw-mt-[5px] tw-px-[12px] tw-flex tw-items-center tw-text-[12px] tw-text-body-text-grey'>
                                                <div className='tw-flex tw-items-center'>
                                                    <EarthIcon width="16" height="16" color='#737B8B' className="tw-mr-[8px]" />
                                                    <span className=''>{commentTime}</span>
                                                </div>
                                                {/* <div className='tw-ml-[18px] tw-flex tw-items-center'>
                                                    <span className='tw-mr-[0px] tw-text-[12px]'>{Math.ceil(Math.random() * 30)}</span>

                                                    <button class="like-button tw-mt-[2px]">
                                                        <div class="like-wrapper">
                                                            <div class="ripple"></div>
                                                            <svg class="heart" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z"></path>
                                                            </svg>
                                                            <div class="particles" style={{ '--total-particles': 6 }}>
                                                                <div class="particle" style={{ '--i': 1, '--color': "#7642F0" }}></div>
                                                                <div class="particle" style={{ '--i': 2, '--color': "#AFD27F" }}></div>
                                                                <div class="particle" style={{ '--i': 3, '--color': "#DE8F4F" }}></div>
                                                                <div class="particle" style={{ '--i': 4, '--color': "#D0516B" }}></div>
                                                                <div class="particle" style={{ '--i': 5, '--color': "#5686F2" }}></div>
                                                                <div class="particle" style={{ '--i': 6, '--color': "#D53EF3" }}></div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>)
                                })}
                                <div className='tw-w-full tw-text-center tw-border-t tw-border-[rgba(128, 128, 128, 0.7)] tw-text-[12px]'>
                                    <div className='tw-w-full tw-my-[10px]'>Tesla © 2024</div>
                                    <div className='tw-w-full tw-my-[10px]'>プライバシー&利用規約</div>
                                    <div className='tw-w-full tw-my-[10px]'>ニュース</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        translations: TranslationSelectors.selectTranslations(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        storyActions: bindActionCreators(storyActionCreator, dispatch),
        commentActions: bindActionCreators(storyCommentActionCreator, dispatch),
        fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisingPage);

